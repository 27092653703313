// @ts-strict-ignore
import React from 'react';
import { JOURNAL_PREFIX_PATH } from '@/main/app.constants';
import { BaseEditor, BaseEditorProps, EditorDependencies, EditorProps } from '@/annotation/BaseEditor.organism';
import { createCustomConfig, DefaultCKConfig } from '@/annotation/ckConfiguration';
import { DecoupledEditor } from '@ckeditor/ckeditor5-editor-decoupled';
import _, { DebounceSettings } from 'lodash';
import { headlessJobFormat, removeEncodedImageData } from '@/utilities/utilities';
import i18next from 'i18next';
import { sqPdfExportStore, sqReportStore } from '@/core/core.stores';
import { setupIntersectionObserver } from '@/core/intersectionObserverHub';
import { DEBOUNCE } from '@/core/core.constants';
import { headlessRenderMode } from '@/services/headlessCapture.utilities';
import { toggleFixedWidth } from '@/reportEditor/report.actions';
import { executeLinkAction } from '@/utilities/journalLink.utilities';
import { PluginDependencies } from '@/annotation/ckEditorPlugins/plugins/PluginDependencies';
import { Editor } from '@/annotation/ckEditorPlugins/types/editor';
import { setCKEditorInstance } from '@/annotation/ckEditor.utilities';
import { TOGGLE_CK_SAVING } from '@/annotation/annotation.constants';

export const CK_EDITOR_READ_ONLY_LOCK_ID = 'seeq';
const NESTED_LINK_LEVEL_LIMIT = 2;

const setupCKEditor = function (deps: EditorDependencies, props: EditorProps) {
  const {
    document,
    id: docId,
    documentChanged,
    initialLanguage,
    toolbar = DefaultCKConfig.toolbar,
    plugins = [],
    afterOnInit,
    domId,
    shouldFocusOnInit,
  } = props;

  let editor;
  let savedHtml = document;
  let activeConfiguration;
  let languageToUse = initialLanguage;

  const setInitialDocument = (e) => {
    editor = e;
    if (savedHtml) {
      editor.setData(savedHtml);
    }
    return e;
  };

  /**
   * If the CK language file could be created as an es6 module then dynamic `import` could be used instead.
   */
  const loadLanguages = () =>
    new Promise((resolve, reject) => {
      if (window.document.getElementById('ckLanguagesScript')) {
        resolve(true);
        return;
      }

      const s = window.document.createElement('script');
      s.setAttribute('src', `/i18n/ckeditor-languages.js?t=${process.env.CK_PACKAGE_HASH}`);
      s.setAttribute('id', 'ckLanguagesScript');
      s.onerror = reject;
      s.onload = resolve;
      window.document.body.appendChild(s);
    });

  const changePaginationStatus = (isEnabled) => {
    let displayStatus = 'none';
    if (isEnabled) {
      editor.plugins.get('Pagination').clearForceDisabled('FixedPagePagination');
      displayStatus = '';
    } else {
      editor.plugins.get('Pagination').forceDisabled('FixedPagePagination');
    }
    // change display status for the pagination buttons
    if (activeConfiguration.toolbar.indexOf('previousPage') === -1) return;
    const toolbarItems = editor.ui.view.toolbar.items;
    toolbarItems.get(activeConfiguration.toolbar.indexOf('previousPage')).element.style.display = displayStatus;
    toolbarItems.get(activeConfiguration.toolbar.indexOf('nextPage')).element.style.display = displayStatus;
    toolbarItems.get(activeConfiguration.toolbar.indexOf('pageNavigation')).element.style.display = displayStatus;
  };

  const addCKEventHandlers = () => {
    editor.model.document.on('change:data', defaultHandleChange);
    editor.on(TOGGLE_CK_SAVING, (event, toggleOn) =>
      toggleOn
        ? editor.model.document.on('change:data', defaultHandleChange)
        : editor.model.document.off('change:data', defaultHandleChange),
    );
    editor.on('toggleFixedWidth', () => {
      changePaginationStatus(!sqReportStore.isFixedWidth);
      toggleFixedWidth();
    });
    const toolbarItems = editor.ui.view.toolbar.items;
    toolbarItems?.map((item) => {
      // tooltipPosition 's' is the default value
      // we modify only the default values and we want to keep the custom tooltip position
      if (item.buttonView) {
        if (item.buttonView.tooltipPosition === 's') {
          item.buttonView.tooltipPosition = 'se';
        }
        return item.buttonView.tooltipPosition;
      } else {
        if (item.tooltipPosition === 's') {
          item.tooltipPosition = 'se';
        }
        return item.tooltipPosition;
      }
    });
  };

  const addToolbarToDOM = () => {
    if (!editor) return;
    window.document.getElementById(`${domId}ToolbarContainer`).appendChild(editor.ui.view.toolbar.element);
  };

  const removeToolbarFromDOM = () => {
    if (!editor) return;
    window.document.getElementById(`${domId}ToolbarContainer`).removeChild(editor.ui.view.toolbar.element);
  };

  const init = (isEditMode = true) => {
    if (editor) {
      sqReportStore.backupPreview ? removeToolbarFromDOM() : addToolbarToDOM();
      if (isEditMode) {
        editor.disableReadOnlyMode(CK_EDITOR_READ_ONLY_LOCK_ID);
        addCKEventHandlers();
      } else {
        editor.enableReadOnlyMode(CK_EDITOR_READ_ONLY_LOCK_ID);
      }
      if (isEditMode && shouldFocusOnInit) {
        editor.editing.view.focus();
      }
      return;
    }
    loadLanguages()
      .then(() => (headlessRenderMode() ? headlessJobFormat().then((format) => format === 'PDF') : false))
      .then((isPDF) => {
        activeConfiguration = createCustomConfig(
          plugins,
          {
            annotationId: docId,
            canModify: isEditMode,
            isPDF,
          },
          toolbar as any,
        );
        activeConfiguration.language = languageToUse;
        const marginValue = `${sqPdfExportStore.margin.value}${sqPdfExportStore.margin.units}`;
        activeConfiguration.pagination = {
          pageWidth: sqPdfExportStore.paperSize.width,
          pageHeight: sqPdfExportStore.paperSize.height,
          pageMargins: {
            top: marginValue,
            bottom: marginValue,
            right: marginValue,
            left: marginValue,
          },
        };
        activeConfiguration.placeholder = i18next.t('REPORT.EDITOR.PLACEHOLDER');
        return activeConfiguration;
      })
      .then((ckConfig) => DecoupledEditor.create(window.document.getElementById(domId), ckConfig))
      .then((editor: Editor) => setCKEditorInstance(editor, domId))
      .then((editor) => {
        const useInspector = false; // Toggle to true if you need to debug CK
        if (process.env.NODE_ENV === 'development' && useInspector) {
          return import('@ckeditor/ckeditor5-inspector')
            .then(({ default: CKEditorInspector }) => CKEditorInspector.attach(editor))
            .then(() => editor);
        }
        return editor;
      })
      /**
       * setup an intersectionObserverHub for the journalEditor where the content elements will be notified if they
       * should update or not based on the position in the viewport
       */
      .then((editor) => {
        setupIntersectionObserver(window.document.getElementById(domId).parentElement);
        return editor;
      })
      .then(setInitialDocument)
      .then(() => {
        if (!isEditMode) {
          editor.enableReadOnlyMode(CK_EDITOR_READ_ONLY_LOCK_ID);
        } else {
          editor.disableReadOnlyMode(CK_EDITOR_READ_ONLY_LOCK_ID);
        }
        if (isEditMode) {
          addToolbarToDOM();
          addCKEventHandlers();
          if (shouldFocusOnInit) {
            editor.editing.view.focus();
          }
        }

        if (!sqReportStore.isFixedWidth) {
          changePaginationStatus(false);
        }

        // Block link popup when it is a special journal link that the user should not be able to edit
        editor.editing.view.document.on(
          'click',
          (ckEvent, domEvent) => {
            //formatted link element has a href attribute on a parent, but onClick targets the innermost element
            const attrValue = getClosestLinkFromAncestor(domEvent.target, NESTED_LINK_LEVEL_LIMIT)?.getAttribute(
              'href',
            );
            const linkCommand = editor.commands.get('link');

            if (attrValue && attrValue.indexOf(JOURNAL_PREFIX_PATH) === 0) {
              // disable link button from toolbar
              linkCommand.forceDisabled('link');
              const urlParams = new URLSearchParams(attrValue.substring(attrValue.indexOf('?')));
              executeLinkAction(urlParams);
              // prevent default panel being shown
              ckEvent.stop();
              // CRAB-33815: Stop browser event when in view mode
              domEvent.preventDefault();
            } else {
              linkCommand.clearForceDisabled('link');
            }
          },
          { priority: 'high' },
        );

        //CRAB-29697: workaround to open an imageLink because ckeditor is blocking it
        editor.editing.view.document.on(
          'click',
          (event, data) => {
            const currentElement = data.domTarget;
            const elementFirstParent = currentElement.parentElement;
            const elementSecondParent = elementFirstParent?.parentElement;
            if (
              editor.isReadOnly &&
              ((currentElement.tagName.toLowerCase() === 'img' && elementFirstParent.tagName.toLowerCase() === 'a') ||
                (currentElement.tagName.toLowerCase() === 'img' && elementSecondParent?.tagName.toLowerCase() === 'a'))
            ) {
              //This will stop the event that is triggered internally by CKEditor. That events handler would stop the
              // default browser event to open up the link. In these cases where an image (img) is included in an anchor
              // (a) we need to let the browser open up the link, so CKEditors event propagation needs to be stopped.
              event.stop();
            }
          },
          { priority: 'highest' },
        );

        editor.editing.view.document.on(
          'enter',
          (evt, data) => {
            const currentElement = evt.startRange.start.parent;
            const rangeOffset = evt.startRange.start.offset;
            // if it's a PRE element
            // && we're after the last element
            // && the last element is a BR element
            if (
              currentElement.name === 'pre' &&
              rangeOffset === currentElement._children.length &&
              currentElement._children[rangeOffset - 1].name === 'br'
            ) {
              // we're gonna let the Enter go ahead
              return;
            }
            // Cancel existing event and simulate a shiftEnter
            editor.execute('shiftEnter');
            data.preventDefault();
            evt.stop();
          },
          { context: 'pre', priority: 'high' },
        );

        afterOnInit(editor);
      })
      .catch((error) => {
        console.error('Failed initializing CKEDitor: ', error.stack);
      });
  };

  /**
   * Traverses parent elements up to limited level and finds a link
   * */
  const getClosestLinkFromAncestor = (element, limit) => {
    let maybeLink = element;
    let index = 0;
    while (maybeLink?.name !== 'a' && index < limit) {
      index = index++;
      maybeLink = maybeLink?.parent;
      if (!maybeLink) {
        return null;
      }
    }
    return maybeLink;
  };

  /**
   * Calls the documentChanged() callback to save the current state of the document in the editor.
   */
  const updated = (forceSave: boolean): Promise<object | void> => {
    if (!editor) {
      return Promise.resolve();
    }
    const document = updateHtmlDocument();
    savedHtml = document;
    return Promise.resolve(documentChanged.current(document, forceSave));
  };

  const syncHtml = (document) => {
    savedHtml = document;
  };

  const syncId = (id: string) => {
    if (!editor) return;
    // If ID changes it is loading a new annotation and it should auto-focus
    if (shouldFocusOnInit) {
      editor.editing.view.focus();
    }
    editor.config.set(PluginDependencies.pluginName, {
      ...editor.config.get(PluginDependencies.pluginName),
      annotationId: id,
    });
  };

  /**
   * We need to use the isAllowedDuringTest param here as the _.debounce is mocked in system tests.
   * This causes issues with the saving of the document as it happens on all characters instead of
   * latest character typed in the document. When system tests will check if the document is saved,
   * it will actually be triggered by previous characters (based on delays) and the last characters
   * from the text will not be saved
   */
  const handleChange = _.debounce(updated, DEBOUNCE.MEDIUM, {
    isAllowedDuringTest: true,
  } as DebounceSettings);
  const defaultHandleChange = () => handleChange(false);

  /**
   * Helper function that removes encoded image data
   */
  const updateHtmlDocument = (): string => {
    if (!editor) return '';

    const htmlContent = removeEncodedImageData(editor.getData());
    return htmlContent;
  };

  const setLanguage = (language) => {
    if (languageToUse === language) return;
    languageToUse = language;

    if (!editor) return;
    destroy();
    init();
  };

  /**
   * Destroys the Journal editor
   */
  const destroy = () => {
    setCKEditorInstance(undefined, domId);
    if (!editor) return;

    editor.destroy();
    editor = null;
    // remove toolbar elements from DOM
    const toolbar = window.document.getElementById(`${domId}ToolbarContainer`);
    if (toolbar) {
      toolbar.textContent = '';
    }
  };

  const getHtml = (): string => savedHtml;

  return {
    init,
    handleChange,
    setLanguage,
    getHtml,
    syncHtml,
    syncId,
    updated,
    destroy,
  };
};

export const CKEditor: React.FunctionComponent<BaseEditorProps> = (props) => (
  <BaseEditor {...props} setupEditor={setupCKEditor} />
);
