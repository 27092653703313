// @ts-strict-ignore
/* istanbul ignore file */

import React from 'react';
import _ from 'lodash';
import { Command, Plugin } from '@ckeditor/ckeditor5-core';
import { toWidget, viewToModelPositionOutsideModelElement } from '@ckeditor/ckeditor5-widget/src/utils';
import { Widget } from '@ckeditor/ckeditor5-widget';
import icon from '@/annotation/ckEditorPlugins/ckIcons/ckeditor5-seeq-content.svg';
import { createDropdown } from '@ckeditor/ckeditor5-ui/src/dropdown/utils';
import priorities from '@ckeditor/ckeditor5-utils/src/priorities';
import {
  forceReloadContent,
  getContentDisplayMode,
  getContentSpecificCommand,
  moveNode,
  renderElementWithPortal,
  renderElementWithRoot,
  replacementImageUtils,
} from '@/annotation/ckEditorPlugins/CKEditorPlugins.utilities';
import { Root } from 'react-dom/client';
import {
  AttributeToListenTo,
  BasePluginDependencies,
  CONTENT_ATTRIBUTE_DOWNCAST_CONVERSIONS,
  CONTENT_ATTRIBUTE_UPCAST_CONVERSIONS,
  CONTENT_MODEL_ATTRIBUTES,
  ContentCallbacks,
  DATA_SEEQ_CONTENT,
  ImageContentListenerCommand,
} from '@/annotation/ckEditorPlugins/CKEditorPlugins.constants';
import { DuplicatingContent } from '@/annotation/ckEditorPlugins/components/DuplicatingContent.molecule';
import ArrowlessDropdownButton from '@/annotation/ckEditorPlugins/views/ArrowlessDropdownButton';
import { InsertContentDropdown } from '@/annotation/InsertContentDropdown.molecule';
import { getImageDataURL } from '@/annotation/reportContent.utilities';
import { HtmlPortalNode } from 'react-reverse-portal';

import { PluginDependencies } from '@/annotation/ckEditorPlugins/plugins/PluginDependencies';
import { isLocalImage as isBase64Image } from '@ckeditor/ckeditor5-image/src/imageupload/utils';
import { ContentLoadObserver, ContentResizeHandles } from '@/annotation/ckEditorPlugins/plugins/content/ContentResize';
import { exportedBase64guid as base64guid } from '@/utilities/utilities';
import i18next from 'i18next';
import { CONTENT_LOADING_CLASS } from '@/reportEditor/report.constants';
import { sqReportStore } from '@/core/core.stores';
import { IGNORE_CK_PAGINATION_VIEW_SELECTOR } from '@/annotation/contentSelector.utilities';
import ImageUtils from '@ckeditor/ckeditor5-image/src/imageutils';
import { TOGGLE_CK_SAVING } from '@/annotation/annotation.constants';

type CkContentMetadata = { modelElement: any; portalNode: HtmlPortalNode };

export const PASTE_ID = 'data-paste-id';
const dataSeeqContentElement = (contentId: string) => {
  return document.querySelector(`${IGNORE_CK_PAGINATION_VIEW_SELECTOR} [${DATA_SEEQ_CONTENT}="${contentId}"]`);
};

export class Content extends Plugin {
  static pluginName = 'Content';
  static setup = {
    name: Content.pluginName,
    plugin: Content,
    toolbar: Content.pluginName,
  };

  // A unique ID generated at plugin generation time that allows copy pasted content in a fast follow situation to
  // know whether its being rendered in the editor it was copied from or another editor, allowing us to dedupe
  // requests for Content and instead wait for the source document to finish the duplication request.
  private pasteId = base64guid();
  private contentIdsBeingCopied = new Set<string>();
  // exposed for testing
  contents = new Map<string, CkContentMetadata>();
  private useCkCopyLogic = true;
  private isSettingHtml = false;
  private portalWrapperElements: Root[] = [];
  private batchToUse = null;

  updateContentModelAndNode(
    editor: any,
    contentId: string,
    modelElement: any,
    portalNode: HtmlPortalNode,
    contentIdChanged = false,
  ) {
    this.contents[contentId] = { modelElement, portalNode };

    // If this method is called, it means the content is 100% no longer pending
    Content.updateContentAttributeWithoutSaving(CONTENT_MODEL_ATTRIBUTES.PENDING, undefined, modelElement);
    if (contentIdChanged) {
      // we need to append this change to the old change batch using enqueue
      this.batchToUse = Content.batchUpdateContentAttribute(
        editor,
        DATA_SEEQ_CONTENT,
        contentId,
        this.contents[contentId].modelElement,
      );
    }
  }

  private updateContentAttributeById(editor: any, attribute: string, value: string, contentId: string, save = true) {
    save
      ? Content.updateContentAttribute(editor, attribute, value, this.contents[contentId].modelElement)
      : Content.updateContentAttributeWithoutSaving(attribute, value, this.contents[contentId].modelElement);
  }

  private updateContentSize(width: number, height: number, modelElement: any) {
    if (this.batchToUse) {
      this.editor.model.enqueueChange(this.batchToUse, (writer) => {
        writer.setAttribute(CONTENT_MODEL_ATTRIBUTES.WIDTH, width, modelElement);
        writer.setAttribute(CONTENT_MODEL_ATTRIBUTES.HEIGHT, height, modelElement);
      });
      this.batchToUse = null;
    } else {
      this.editor.model.change((writer) => {
        writer.setAttribute(CONTENT_MODEL_ATTRIBUTES.WIDTH, width, modelElement);
        writer.setAttribute(CONTENT_MODEL_ATTRIBUTES.HEIGHT, height, modelElement);
      });
    }
  }

  private static updateContentAttributeWithoutSaving(attribute: string, value: string, modelElement: any) {
    modelElement._setAttribute(attribute, value);
  }

  private static updateContentAttribute(editor: any, attribute: string, value: string, modelElement: any) {
    editor.model.change((writer) => {
      writer.setAttribute(attribute, value, modelElement);
    });
  }

  private static batchUpdateContentAttribute(editor: any, attribute: string, value: string, modelElement: any) {
    return editor.model.change((writer) => {
      writer.setAttribute(attribute, value, modelElement);
      return writer.batch;
    });
  }

  private getContentModel(contentId: string): any | undefined {
    return this.contents[contentId]?.modelElement;
  }

  // Users can't conveniently copy Content from Seeq due to the security on the endpoint stopping the image from
  // being fetched outside of the Seeq environment. Img srcs can point at data blobs which it renders as an image
  // though. This goes through each piece of Seeq content, generates a canvas for each image, and sets Content's
  // src to said blob, allowing this to be copy pasted into external applications.
  private modifyAttributesForAllContentOnCopyCut(children: any[], editor: any, appendPasteId = false) {
    _.forEach(children, (child: any) => {
      if (child.childCount > 0) {
        this.modifyAttributesForAllContentOnCopyCut(Array.from(child.getChildren()), editor, appendPasteId);
      } else {
        if (child.name === 'content') {
          const contentId = child.getAttribute(DATA_SEEQ_CONTENT);
          const model = this.contents[contentId].modelElement;
          if (!sqReportStore.getContentById(contentId).isReact) {
            // Selection models are different from the models used by the document for some reason
            Content.updateContentAttribute(editor, 'src', getImageDataURL(dataSeeqContentElement(contentId)), model);
            setTimeout(() => Content.updateContentAttribute(editor, 'src', undefined, model));
          }
          appendPasteId && Content.updateContentAttributeWithoutSaving(PASTE_ID, this.pasteId, model);
          this.contentIdsBeingCopied.add(contentId);
        }
      }
    });
  }

  private onCopyCut(editor: any, appendPasteId = false) {
    this.contentIdsBeingCopied.clear();
    this.modifyAttributesForAllContentOnCopyCut(
      Array.from(editor.model.getSelectedContent(editor.model.document.selection).getChildren()),
      editor,
      appendPasteId,
    );
  }

  private setUseCkCopyLogic(useCkCopyLogic: boolean) {
    this.useCkCopyLogic = useCkCopyLogic;
  }

  static get requires() {
    return [Widget, ContentResizeHandles];
  }

  init() {
    this.defineSchema();
    this.defineConverters();
    this.defineToolbarButton();
    this.defineClipboardHandlers();
    this.defineAttributeEvents();

    // Expose insert content command
    const editor = this.editor;
    editor.commands.add('insertContent', new (InsertContentCommand as any)(this.editor));

    // Because of the inline-ness of our content widget, the internal model can sometimes fall out of place with the
    // view. This helps prevent that.
    editor.editing.mapper.on(
      'viewToModelPosition',
      viewToModelPositionOutsideModelElement(editor.model, (viewElement) => viewElement.hasClass('ck-widget')),
    );

    // This guarantees the resize content load observer is registered before any content is inserted
    const editingView = editor.editing.view;
    editingView.addObserver(ContentLoadObserver);

    const updateContentModelAndNode = this.updateContentModelAndNode.bind(this);
    const updateContentAttributeById = this.updateContentAttributeById.bind(this);
    const updateContentSize = this.updateContentSize.bind(this);
    const getCurrentModel = this.getContentModel.bind(this);
    const setUseCkCopyLogic = this.setUseCkCopyLogic.bind(this);
    editor.config.define(Content.pluginName, {
      contentCallbacks: {
        updateContentAttributeById,
        updateContentModelAndNode,
        getCurrentModel,
        updateContentAttributeWithoutSaving: Content.updateContentAttributeWithoutSaving,
        setUseCkCopyLogic,
        updateContentSize,
      } as ContentCallbacks,
    });

    // CRAB-26236: CK isn't smart about clearing its current content if you set new data (fast follow), so we tell the
    // Content plugin to reuse the existing components to save on component generation when saving is disabled, which is
    // called as part of fast follow
    editor.on(TOGGLE_CK_SAVING, (event, toggle) => {
      this.isSettingHtml = !toggle;
    });
  }

  // CK will call `destroy` on every plugin when `editor.destroy()` is called. We call it in `CKEditor.organism`
  destroy() {
    this.portalWrapperElements.forEach((root) => {
      root.unmount();
    });
  }

  defineAttributeEvents() {
    const editor = this.editor;
    _.forEach(ImageContentListenerCommand, (command) => {
      const attribute = AttributeToListenTo[command];
      editor.conversion.for('downcast').add((dispatcher) =>
        // Dedicated converter to fire content specific event for each attribute.
        dispatcher.on(`attribute:${attribute}:content`, (evt, data, conversionApi) => {
          if (!conversionApi.consumable.consume(data.item, evt.name)) {
            return;
          }
          editor.fire(
            getContentSpecificCommand(command, data.item.getAttribute(DATA_SEEQ_CONTENT)),
            data.attributeNewValue,
          );
        }),
      );
    });
  }

  defineClipboardHandlers() {
    const editor = this.editor;
    const deps: BasePluginDependencies = editor.config.get(PluginDependencies.pluginName);

    // We don't need to handle pasting because we generate the src dynamically.
    editor.editing.view.document.on('copy', (event) => {
      // CK's default copy logic will override an html range copy
      if (!this.useCkCopyLogic) {
        event.stop();
        return;
      }
      this.onCopyCut(editor, true);
    });

    editor.editing.view.document.on('cut', (event) => {
      this.onCopyCut(editor);
    });

    // Since Seeq content is saved as imgs, CK's default image processors try to interfere with the base 64 blob on
    // paste. This sets an attribute which CK uses internally and skips the normal paste process that images go through.
    editor.plugins.get('ClipboardPipeline').on(
      'inputTransformation',
      (evt, data) => {
        _.forEach(Array.from(editor.editing.view.createRangeIn(data.content)), (value: any) => {
          if (
            isBase64Image(replacementImageUtils as ImageUtils, value.item) &&
            value.item.getAttribute(DATA_SEEQ_CONTENT)
          ) {
            value.item._setAttribute('uploadProcessed', '1');
          }
        });
      },
      {
        priority: priorities.high,
      },
    );
  }

  defineSchema() {
    this.editor.model.schema.register('content', {
      isObject: true,
      isInline: true,
      allowWhere: '$text',
      allowAttributes: [DATA_SEEQ_CONTENT, ..._.values(CONTENT_MODEL_ATTRIBUTES), PASTE_ID, 'class'],
    });
  }

  /**
   * Attempts to get attribute from elementToGet and sets the value an attribute on elementToSet
   * we don't want to set an empty attribute (0 or "") with the exception of WIDTH and HEIGHT
   * since the width and height can have a value of 0 for contents using images from renderer
   */
  private static maybeSetElement(elementToGet: any, elementToSet: any, attribute: string, customConversions: any) {
    const value = elementToGet.getAttribute(attribute);
    (value || [CONTENT_MODEL_ATTRIBUTES.WIDTH, CONTENT_MODEL_ATTRIBUTES.HEIGHT].includes(attribute)) &&
      elementToSet._setAttribute(attribute, customConversions[attribute]?.(value) ?? value);
  }

  defineConverters() {
    const editor = this.editor;
    const conversion = editor.conversion;
    const deps: BasePluginDependencies = editor.config.get(PluginDependencies.pluginName);

    // <content> converters ((data) view → model)
    conversion.for('upcast').elementToElement({
      // Explanation:
      // https://ckeditor.com/docs/ckeditor5/latest/api/module_engine_view_matcher-MatcherPattern.html
      view: {
        name: 'img',
        attributes: {
          [DATA_SEEQ_CONTENT]: true,
        },
      },
      upcastAlso: {
        name: 'a',
        attributes: {
          [DATA_SEEQ_CONTENT]: true,
          href: true,
        },
      },
      model: (viewElement, { writer: modelWriter }) => {
        // Read the "data-seeq-content" attribute from the view and set it as the "data-seeq-content" in the
        // model.
        const modelElement = modelWriter.createElement('content', {
          [DATA_SEEQ_CONTENT]: viewElement.getAttribute(DATA_SEEQ_CONTENT),
        });
        _.forEach({ ...CONTENT_MODEL_ATTRIBUTES, pasteId: PASTE_ID }, (attribute) =>
          Content.maybeSetElement(viewElement, modelElement, attribute, CONTENT_ATTRIBUTE_UPCAST_CONVERSIONS),
        );
        return modelElement;
      },
      // This will get run before all other matches, skipping normal image upcasting.
      converterPriority: 'highest',
    });

    // <content> converters (model → data view)
    conversion.for('dataDowncast').elementToElement({
      model: 'content',
      view: (modelElement, conversionApi) => {
        const url = `/api/content/${modelElement.getAttribute(DATA_SEEQ_CONTENT)}/sourceUrl`;
        const placeholderView = conversionApi.writer.createContainerElement('a', {
          href: url,
          [DATA_SEEQ_CONTENT]: modelElement.getAttribute(DATA_SEEQ_CONTENT),
        });
        // In the data view, the model <content> corresponds to:
        // <img data-seeq-content="..."/>
        const viewElement = conversionApi.writer.createEmptyElement('img', {
          [DATA_SEEQ_CONTENT]: modelElement.getAttribute(DATA_SEEQ_CONTENT),
          src: modelElement.getAttribute('src'),
        });
        conversionApi.writer.insert(conversionApi.writer.createPositionAt(placeholderView, 0), viewElement);

        _.forEach({ ...CONTENT_MODEL_ATTRIBUTES, PASTE_ID }, (attribute) =>
          Content.maybeSetElement(modelElement, viewElement, attribute, CONTENT_ATTRIBUTE_DOWNCAST_CONVERSIONS),
        );
        return placeholderView;
      },
    });

    // <content> converters (model → editing view)
    conversion.for('editingDowncast').elementToElement({
      model: 'content',
      view: (modelElement, { writer: viewWriter }) => {
        const customWidth = _.toNumber(modelElement.getAttribute(CONTENT_MODEL_ATTRIBUTES.WIDTH));
        const customHeight = modelElement.getAttribute(CONTENT_MODEL_ATTRIBUTES.HEIGHT);
        const options = modelElement.getAttribute(CONTENT_MODEL_ATTRIBUTES.PROPERTY_OVERRIDES);

        const reactWrapperStyle = `max-width: ${customWidth}px;` + `min-height: ${customHeight}px;` + 'width: 100%;';
        // In the editing view, the model <content> corresponds to:
        //
        //  <div class="inlineBlock seeqContentWrapper">
        //    <div class="contentWrapper">
        //      <Content dataSeeqContent="..." /> (React component)
        //    </div>
        //  </div>
        let width = reactWrapperStyle;
        if (modelElement.getAttribute(CONTENT_MODEL_ATTRIBUTES.WIDTH_PERCENT)) {
          width = `width: ${modelElement.getAttribute(CONTENT_MODEL_ATTRIBUTES.WIDTH_PERCENT)};`;
        } else if (!options?.showChartView || customWidth === 0 || !_.isNumber(customWidth)) {
          width = 'auto';
        }
        const outerWrapper = viewWriter.createContainerElement('div', {
          class: 'inlineBlock seeqContentWrapper',
          style: width,
        });
        const contentId = modelElement.getAttribute(DATA_SEEQ_CONTENT);

        // This element will host a React <Content /> component.
        const reactWrapper = viewWriter.createRawElement(
          'div',
          {
            class: `${CONTENT_LOADING_CLASS.REACT_WRAPPER} inheritMinHeight`,
          },
          (domElement) => {
            // This the place where React renders the actual content hosted
            // by a UIElement in the view.
            const content = this.contents[contentId];

            // Since Content is inline, it can get deleted and reinserted when it gets moved down a line from someone
            // hitting enter. To prevent us having to rerender the content, we use REVERSE PORTALS to simply move
            // around the node referencing the Content to a new place.
            // See https://github.com/httptoolkit/react-reverse-portal and
            // CKEditorPlugins.utilities.tsx::renderElementWithPortal for an overview.

            const pasteId = modelElement.getAttribute(PASTE_ID);
            if (
              content &&
              (!dataSeeqContentElement(contentId) ||
                (!this.contentIdsBeingCopied.has(contentId) && !pasteId) ||
                (this.isSettingHtml && !pasteId))
            ) {
              moveNode(domElement, content.portalNode);
              this.contents[contentId] = { ...content, modelElement };
              // we need to force a refresh / reload on the image
              // Once the component is rendered it takes a while for the rerenders needed to have the final form
              // rendered (based on stores etc) so this allows for some time for the re-renders to happen. Timeout
              // value chosen based on testing and a framerate of 50-60fps. Calculation is thus: 1000ms / 50fps = 20ms.
              setTimeout(() => forceReloadContent(contentId), 20);
            } else if (
              this.isSettingHtml &&
              modelElement.getAttribute(PASTE_ID) &&
              modelElement.getAttribute(PASTE_ID) !== this.pasteId
            ) {
              // Show a pending piece of content while waiting for real Content to come from the other documents paste
              domElement.classList.add(CONTENT_LOADING_CLASS.SPINNER);
            } else {
              this.portalWrapperElements.push(
                renderElementWithPortal(deps, domElement, DuplicatingContent, {
                  modelElement,
                  contentId,
                  displayMode: getContentDisplayMode(deps.canModify, deps.isPDF),
                }),
              );
              Content.updateContentAttributeWithoutSaving(PASTE_ID, undefined, modelElement);
            }
          },
        );
        viewWriter.insert(viewWriter.createPositionAt(outerWrapper, 0), reactWrapper);
        return toWidget(outerWrapper, viewWriter, { label: 'content widget' });
      },
    });
    // attribute changes
    conversion.for('editingDowncast').add((dispatcher) =>
      dispatcher.on('attribute', (evt, data, conversionApi) => {
        const modelElement: ModelElement = data.item as ModelElement;
        if (modelElement.name !== 'content') {
          return;
        }
        conversionApi.consumable.consume(data.item, evt.name);
        const viewElement = conversionApi.mapper.toViewElement(modelElement);
        if (!viewElement) {
          return;
        }
        const options = modelElement.getAttribute(CONTENT_MODEL_ATTRIBUTES.PROPERTY_OVERRIDES);
        const customWidth = _.toNumber(modelElement.getAttribute(CONTENT_MODEL_ATTRIBUTES.WIDTH));
        const customHeight = modelElement.getAttribute(CONTENT_MODEL_ATTRIBUTES.HEIGHT);
        const widthPercent = modelElement.getAttribute(CONTENT_MODEL_ATTRIBUTES.WIDTH_PERCENT);
        const writer = conversionApi.writer as ViewWriter;

        writer.removeClass('tableContainer', viewElement);
        if (widthPercent) {
          writer.setAttribute('style', `width: ${widthPercent};`, viewElement);
        } else if (
          // content is a table, not in chart view
          options?.showChartView === false ||
          customWidth === 0 ||
          !_.isNumber(customWidth)
        ) {
          // Minimum dimensions are needed for tables because there are no dimensions for the div to inherit
          writer.addClass('tableContainer', viewElement);
          writer.removeAttribute('style', viewElement);
        } else {
          const styleToSet = `max-width: ${customWidth}px;` + `min-height: ${customHeight}px;` + 'width: 100%';
          writer.setAttribute('style', styleToSet, viewElement);
        }
      }),
    );
  }

  defineToolbarButton() {
    const editor = this.editor;
    editor.ui.componentFactory.add('Content', (locale) => {
      const dropdown = createDropdown(locale, ArrowlessDropdownButton);
      const deps: BasePluginDependencies = editor.config.get(PluginDependencies.pluginName);

      dropdown.buttonView.set({
        label: i18next.t('REPORT.EDITOR.CONTENT_BUTTON_TITLE'),
        icon,
        tooltipPosition: 'se',
        tooltip: true,
        class: 'contentBtn',
      });

      dropdown.panelView.on('render', (eventInfo) => {
        renderElementWithRoot(
          deps,
          eventInfo.source.element,
          <InsertContentDropdown
            onClick={() => {
              dropdown.isOpen = false;
            }}
          />,
        );
      });

      return dropdown;
    });
  }
}

export class InsertContentCommand extends Command {
  execute(ignored, contentId) {
    const editor = this.editor;
    const content = sqReportStore.getContentById(contentId);
    editor.model.change((writer) => {
      // Insert <content id="...">*</content> at the current selection position
      // in a way which will result in creating a valid model structure.
      editor.model.insertObject(
        writer.createElement('content', {
          [DATA_SEEQ_CONTENT]: contentId,
          [CONTENT_MODEL_ATTRIBUTES.BORDER]: !content.useSizeFromRender,
          [CONTENT_MODEL_ATTRIBUTES.NO_MARGIN]: false,
          [CONTENT_MODEL_ATTRIBUTES.HEIGHT]: content.height,
          [CONTENT_MODEL_ATTRIBUTES.WIDTH]: content.width,
        }),
      );
      // we need to focus back on the editor after a short delay
      setTimeout(() => {
        editor.editing.view.focus();
      }, 500);
    });
  }

  refresh() {
    const model = this.editor.model;
    const selection = model.document.selection;
    const allowedIn = model.schema.findAllowedParent(selection.getFirstPosition(), 'content');

    this.isEnabled = allowedIn !== null;
  }
}
